import React, { useEffect, useState } from 'react'
import { useMediaQuery } from '@material-ui/core'

import styles from './index.module.scss'

import { ContentType } from '../../types/trialLessons'
import { THRESHOLD_DESKTOP_WIDTH } from '../../utils/constants'
import { AwardContentOns } from '../AwardContentOns'
import { AwardContentOtl } from '../AwardContentOtl'
import CmSectionMovie from '../CmSectionMovie'
import { useRouter } from 'next/router'
import { useCampaignAndCountdownStatus } from '../../utils/campaignCountdown'

type Props = {
  contentType: ContentType
}

const RESKILLING_SOURCES: string[] = ['deca', 'karte', 'lks']

export const AwardContent = ({ contentType }: Props) => {
  const [showReskilling, setShowReskilling] = useState<boolean>(false)
  const { isNextCampaignStarted } = useCampaignAndCountdownStatus()

  const router = useRouter()
  const isOns = contentType.includes('Ons')
  const isPc = useMediaQuery(`(min-width:${THRESHOLD_DESKTOP_WIDTH}px)`)

  // NOTE: RESKILLING_SOURCESでの出し分けは2024年末時点では行わない方針となったが、
  //       CRMチームとの合意が取れていないため、コード上は残しておく
  useEffect(() => {
    if (router?.isReady) {
      const utm_source = router.query.utm_source
      if (RESKILLING_SOURCES.some(s => s === utm_source)) {
        setShowReskilling(true)
      }
    }
  }, [router])

  return (
    <>
      <CmSectionMovie />
      <section id="award-content" className={styles.container}>
        {isOns ? (
          <AwardContentOns isPc={isPc} />
        ) : (
          <AwardContentOtl isPc={isPc} />
        )}
      </section>
    </>
  )
}

import { useState } from 'react'

import styles from './index.module.scss'

import currentCampaignPc from '../../public/campaign_banner_ons_20250303_pc.png'
import currentCampaignPcDetail from '../../public/campaign_banner_detail_ons_20250303_pc.png'

import currentCampaignSp from '../../public/campaign_banner_ons_20250303_sp.png'
import currentCampaignSpDetail from '../../public/campaign_banner_detail_ons_20250303_sp.png'

import nextCampaignPc from '../../public/campaign_banner_ons_20250401_pc.png'
import nextCampaignPcDetail from '../../public/campaign_banner_detail_ons_20250401_pc.png'

import nextCampaignSp from '../../public/campaign_banner_ons_20250401_sp.png'
import nextCampaignSpDetail from '../../public/campaign_banner_detail_ons_20250401_sp.png'

import { AwardContentReskilling } from '../AwardContentReskilling'
import { useCampaignAndCountdownStatus } from '../../utils/campaignCountdown'

type Props = {
  isPc: boolean
}

export const AwardContentOns = ({ isPc }: Props) => {
  const [showCampaignDetail, setShowCampaignDetail] = useState<boolean>(false)
  const { isNextCampaignStarted } = useCampaignAndCountdownStatus()

  const toggleCampaignDetail = () => {
    setShowCampaignDetail(!showCampaignDetail)
  }

  return (
    <div className={styles.container}>
      <AwardContentReskilling isPc={isPc} />
      <div className={styles.campaignContainer}>
        <div
          className={`${
            isNextCampaignStarted
              ? styles.nextCpShowDetailToggle
              : styles.currentCpShowDetailToggle
          } ${
            showCampaignDetail
              ? isNextCampaignStarted
                ? styles.nextCpOpen
                : styles.currentCpOpen
              : isNextCampaignStarted
              ? styles.nextCpClose
              : styles.currentCpClose
          }`}
          onClick={toggleCampaignDetail}
        ></div>
        {showCampaignDetail ? (
          <img
            src={
              isNextCampaignStarted
                ? isPc
                  ? nextCampaignPcDetail.src
                  : nextCampaignSpDetail.src
                : isPc
                ? currentCampaignPcDetail.src
                : currentCampaignSpDetail.src
            }
            alt=""
          />
        ) : (
          <img
            src={
              isNextCampaignStarted
                ? isPc
                  ? nextCampaignPc.src
                  : nextCampaignSp.src
                : isPc
                ? currentCampaignPc.src
                : currentCampaignSp.src
            }
            alt=""
          />
        )}
      </div>
    </div>
  )
}
